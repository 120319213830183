@media only screen and (min-width: 450px) {
    .dateClass{color: black;}
}
@media only screen and (max-width: 450px) {
    .dateClass{color: white;}
}

.navlink{
  font-size: 10px;
  text-decoration: none;
  position: relative;
  display: block;
  font-size: 1.2rem;
  text-transform: uppercase;
  transition: color 0.1s, background-color 0.1s;
}

.navlink:hover {
  color: #E50914;
}

.navlink:focus, .navlink:active {
  color: #E50914;
}

.navlink::before {
  content: '';
  display: block;
  position: absolute;
  top: 100%;
  height: 3px;
  width: 100%;
  background-color: #E50914;
  -webkit-transform-origin: center top;
          transform-origin: center top;
  -webkit-transform: scale(0, 1);
          transform: scale(0, 1);
  transition: color 0.1s, -webkit-transform 0.2s ease-out;
  transition: color 0.1s, transform 0.2s ease-out;
  transition: color 0.1s, transform 0.2s ease-out, -webkit-transform 0.2s ease-out;
}

.navlink:active::before {
  background-color: #E50914;
}

.navlink:hover::before,.navlink:focus::before {
  -webkit-transform-origin: center top;
          transform-origin: center top;
  -webkit-transform: scale(1, 1);
          transform: scale(1, 1);
}