.customHeading{
    text-align: center;
    color: #E50914;
    font-weight: bold;
    font-family: netflix;
}

.timelineCardTitle{
    font-size: 40px; 
    font-weight: bold;
}

.projectCardTitle{
    text-align: center;
    font-family: consolas;
    font-weight: bold;
}